<template>
  <Dialog
    ref="featureModal"
    v-model:visible="visible"
    :closable="true"
    :maximizable="true"
    :modal="true"
    :style="{ width }"
    class="tw-min-w-[50vw]"
    header="Features"
    @hide="closeModal"
  >
    <Timeline
      v-show="showTimeline"
      :value="features"
      align="left"
      class="p-timeline"
      layout="vertical"
    >
      <template #marker="slotProps">
        <span
          :class="[
            slotProps.item.isSeen
              ? 'tw-border-gray-300'
              : 'tw-border-indigo-600',
            { 'tw-bg-indigo-800': !slotProps.item.isSeen },
          ]"
          class="tw-w-5 tw-h-5 tw-rounded-full tw-border-solid tw-border-4 tw-z-1"
        />
      </template>

      <template #content="slotProps">
        <Card class="tw-mb-12">
          <template #title>
            {{ slotProps.item.name }}
          </template>

          <template #subtitle>
            {{ dateFormat(slotProps.item.publishedAt, 'YYYY-MM-DD hh:mm') }}
          </template>

          <template #content>
            <p>
              {{ slotProps.item.shortDescription }}
            </p>

            <Button
              label="Show more"
              text
              @click="showMoreItem(slotProps.item)"
            />
          </template>
        </Card>
      </template>
    </Timeline>

    <div v-show="!showTimeline">
      <h3>{{ feature?.name }}</h3>
      <small>{{ dateFormat(feature?.publishedAt, 'YYYY-MM-DD hh:mm') }} </small>
      <Divider />
      <div v-html="feature?.description"></div>
    </div>
    <template #footer>
      <Button
        v-show="!showTimeline"
        autofocus
        icon="pi pi-arrow-left"
        label="Go back"
        severity="info"
        text
        @click="goBack"
      />
      <Button
        autofocus
        label="Cancel"
        severity="primary"
        text
        @click="closeModal"
      />
    </template>
  </Dialog>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { cloneDeep } from 'lodash-es'
import { featureStatus } from '@/common/constants/status.constant'
import { dateFormat } from '@/common/utils/filters.utils'
import UserService from '@/service/UserService'

export default {
  name: 'FeatureModal',

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: '650px',
      required: false,
    },
  },

  emits: ['cancel'],

  setup(props, { emit }) {
    const store = useStore()
    const hasUnseenFeature = computed(
      () => store.getters['user/hasUnseenFeature'],
    )
    const visible = ref(props.isVisible)
    const feature = ref({})
    const showTimeline = ref(true)

    const currentUser = computed(() => store.getters['auth/authData'].user)

    const seenFeaturesId = computed(() =>
      currentUser.value?.seenFeatures?.map(
        (seenFeature) => seenFeature.featureId,
      ),
    )

    const features = computed(() => {
      let features = cloneDeep(store.getters['feature/getPublishedList'])
      features = features.map((feature) => {
        feature.isSeen = currentUser.value?.seenFeatures?.some(
          (seenFeature) =>
            seenFeature.featureId.toString() === feature._id.toString(),
        )

        return feature
      })

      return features
    })

    const closeModal = () => {
      visible.value = false
      emit('cancel')
    }

    const showMoreItem = async (item) => {
      showTimeline.value = false
      feature.value = item

      if (item.isSeen) return

      await UserService.updateSeenFeatures(item._id)

      item.isSeen = true
    }

    const goBack = () => {
      showTimeline.value = true
    }

    watch(hasUnseenFeature, (newValue) => {
      visible.value = newValue
    })

    watch(
      () => props.isVisible,
      (newValue) => {
        visible.value = newValue
      },
    )
    const featuresIdsConstraints = computed(() => {
      return seenFeaturesId.value?.map((id) => {
        return {
          value: id,
          matchMode: FilterMatchMode.NOT_EQUALS,
        }
      })
    })
    onMounted(async () => {
      await store.dispatch('feature/getPublishedList', {
        filters: {
          status: {
            value: featureStatus.PUBLISHED,
            matchMode: FilterMatchMode.EQUALS,
          },
          _id: {
            operator: FilterOperator.AND,
            constraints: featuresIdsConstraints.value,
          },
        },
        sortField: 'publishedAt',
        sortOrder: -1,
      })
    })

    return {
      visible,
      closeModal,
      features,
      showTimeline,
      showMoreItem,
      feature,
      goBack,
    }
  },

  methods: { dateFormat },
}
</script>
<style lang="scss" scoped>
::v-deep(.p-timeline-event-opposite) {
  display: none;
}

@media screen and (max-width: 960px) {
  ::v-deep(.customized-timeline) {
    .p-timeline-event:nth-child(even) {
      flex-direction: row;

      .p-timeline-event-content {
        text-align: left;
      }
    }

    .p-timeline-event-opposite {
      flex: 0;
    }
  }
}
</style>
