import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class LoggingService extends BaseService {
  constructor() {
    super('logging')
  }

  resetData(idLogging) {
    return protectedRequest({
      url: `${this._apiUrl}/${idLogging}/reset`,
      method: 'post',
    })
  }
}

export default new LoggingService()
