import ProductionService from '../../../service/ProductionService'
import PrebillingService from '../../../service/PrebillingService'
import * as mutationTypes from './types'

export const getAll = async ({ commit }) => {
  const { data } = await ProductionService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getTotalFiltered = async ({ commit }, query) => {
  const { data } = await ProductionService.totalPrebilling(query)
  commit('SET_TOTAL_FILTERED', data)

  return data
}

export const getList = async ({ commit }, query) => {
  const { data } = await ProductionService.paginatedSearchPreBilling(query)
  commit('SET_LIST', data)

  return data
}

export const fetchNode = async ({ commit }, query) => {
  const { data } = await ProductionService.nodes(query)
  commit('SET_LIST', data)

  return data
}

export const getTotal = async ({ commit }, query) => {
  const { data } = await ProductionService.totalPrebilling(query)
  commit('SET_TOTAL', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await ProductionService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const updateItem = async ({ commit }, { id, data }) => {
  const { data: resData } = await ProductionService.update(id, data)
  commit('UPDATE_LIST', resData)

  return resData
}

export const massUpdateItems = async ({ commit }, updatedata) => {
  const { data } = await ProductionService.massUpdate(updatedata)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await ProductionService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const exportExcel = async ({ commit, rootState }, query) => {
  try {
    commit(mutationTypes.exportExcel.requesting)
    const res = await ProductionService.exportExcel(query)
    commit(mutationTypes.exportExcel.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportExcel.failure, { payload: e, rootState })
  }
}

export const countPerState = async ({ commit }, payload) => {
  const { data } = await PrebillingService.countPerState(payload)
  commit('DATA_COUNTED_PER_STATE', data)

  return data
}
