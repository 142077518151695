import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class ProductionService extends BaseService {
  constructor() {
    super('production')

    this.precalculateAmountAC = {}
    this.balanceAC = []
  }

  updateByFeeAndDate(data) {
    return protectedRequest({
      method: 'PUT',
      url: `${this._apiUrl}`,
      data,
    })
  }

  paginatedSearchExternalProduction({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/external-production/query`,
      data: filters,
      params: query,
    })
  }

  paginatedProductionLocalClient({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/production-local-client/query`,
      data: filters,
      params: query,
    })
  }

  totalProductionLocalClient({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/production-local-client/total`,
      data: filters,
      params: query,
    })
  }

  paginatedSearchPostProduction({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/post-production/query`,
      data: filters,
      params: query,
    })
  }

  paginatedSearchExternalPostProduction({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/external-post-production/query`,
      data: filters,
      params: query,
    })
  }

  totalRecordsProduction({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/production/query/totalRecords`,
      data: filters,
      params: query,
    })
  }

  totalRecordsPostProduction({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/post-production/query/totalRecords`,
      data: filters,
      params: query,
    })
  }

  totalRecordsInternalCost({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/internal-cost/query/totalRecords`,
      data: filters,
      params: query,
    })
  }

  totalRecordsExternalProductions({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/external-production/query/totalRecords`,
      data: filters,
      params: query,
    })
  }

  paginatedSearchPreBilling({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/pre-billing/query`,
      data: filters,
      params: query,
    })
  }

  paginatedSearchSales({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/sales/query`,
      data: filters,
      params: query,
    })
  }

  totalPrebilling({ filters }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/pre-billing/total`,
      data: filters,
    })
  }

  totalPostProduction({ filters }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/post-production/total`,
      data: filters,
    })
  }

  totalExternalPostProduction({ filters }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/external-post-production/total`,
      data: filters,
    })
  }

  totalInternalCost({ filters }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/internal-cost/total`,
      data: filters,
    })
  }

  paginatedSearchInternalCost({ filters, ...query }) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/internal-cost/query`,
      data: filters,
      params: query,
    })
  }

  exportExcel(query) {
    return protectedRequest({
      timeout: 300000,
      method: 'POST',
      url: `${this._apiUrl}/exportexcel`,
      data: query,
      responseType: 'blob',
    })
  }

  exportSalesExcel({ filters }) {
    return protectedRequest({
      timeout: 300000,
      method: 'POST',
      url: `${this._apiUrl}/sales/exportexcel`,
      data: filters,
      responseType: 'blob',
    })
  }

  exportCsv({ filters }) {
    return protectedRequest({
      timeout: 300000,
      method: 'POST',
      url: `${this._apiUrl}/exportCsvSales`,
      data: filters,
      responseType: 'blob',
    })
  }

  getProductionsByMonth({ year, month, page, company } = {}) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/productions-by-month`,
      params: {
        year,
        month,
        page,
        company,
      },
    })
  }

  precalculateAmount(id, type, data) {
    this.cleanPrecalculateAmountAC(type)

    const abortController = new AbortController()
    this.precalculateAmountAC[type] ??= []
    this.precalculateAmountAC[type].push(abortController)

    return protectedRequest({
      method: 'post',
      url: `${this._apiUrl}/${id}/precalculate`,
      data: {
        ...data,
        type,
      },
      signal: abortController.signal,
    })
  }

  cleanPrecalculateAmountAC(type) {
    const acs = !type
      ? Object.values(this.precalculateAmountAC).flat()
      : this.precalculateAmountAC?.[type]

    if (acs?.length > 0) {
      acs.forEach((ac) => ac.abort())

      if (!type) {
        this.precalculateAmountAC = {}
      } else {
        this.precalculateAmountAC[type] = []
      }
    }
  }

  getBalance(id, data = {}) {
    this.cleanBalanceAC()

    const abortController = new AbortController()
    this.balanceAC ??= []
    this.balanceAC.push(abortController)

    return protectedRequest({
      method: 'post',
      url: `${this._apiUrl}/${id}/balance`,
      data,
      signal: abortController.signal,
    })
  }

  cleanBalanceAC() {
    if (this.balanceAC.length > 0) {
      this.balanceAC.forEach((ac) => ac.abort())
      this.balanceAC = []
    }
  }

  generateInvoice(ids, billingMethod, companyId) {
    return protectedRequest({
      method: 'post',
      url: `${this._apiUrl}/generate-invoice`,
      data: {
        productionIds: ids,
        billingMethod,
        companyId,
      },
    })
  }

  massUpdateQtyToBeSoldOut(ids, massEditId) {
    return protectedRequest({
      method: 'post',
      url: `${this._apiUrl}/mass-update-qty-to-be-sold-out`,
      data: {
        productionIds: ids,
      },
      params: { massEditId },
    })
  }

  getPlatformsData(id) {
    return protectedRequest({
      method: 'get',
      url: `${this._apiUrl}/${id}/platforms-data`,
    })
  }

  updatePlatformsData(id, platformsData) {
    return protectedRequest({
      method: 'post',
      url: `${this._apiUrl}/${id}/platforms-data`,
      data: { platformsData },
    })
  }

  updateQtyOperatedFromBigQuery(productionsId, ressource, massEditId) {
    return protectedRequest({
      method: 'post',
      timeout: 300000,
      url: `${this._apiUrl}/mass-update-qty-operated-from-big-query`,
      data: { productionsId, ressource },
      params: { massEditId },
    })
  }

  updateAllQtyOperatedFromBigQuery(ressource, massEditId) {
    return protectedRequest({
      method: 'post',
      url: `${this._apiUrl}/update-all-qty-operated-from-big-query`,
      data: { ressource },
      params: { massEditId },
    })
  }

  copyToFuture(productionId, data, massEditId) {
    return protectedRequest({
      method: 'post',
      url: `${this._apiUrl}/mass-update-copy-to-future`,
      data: { productionId, data },
      params: { massEditId },
    })
  }

  remindAssignee(productionId) {
    return protectedRequest({
      method: 'post',
      url: `${this._apiUrl}/${productionId}/remind-assignee`,
    })
  }

  countByDate(payload) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/count-by-date`,
      data: {
        ...payload,
      },
    })
  }
}

export default new ProductionService()
