import InternalProjectService from '@/service/InternalProjectService'
import * as mutationTypes from '@/store/modules/simpleProject/types'

export const getAll = async ({ commit }) => {
  const { data } = await InternalProjectService.getAll()
  commit('SET_LIST', data)

  return data.items
}

export const getList = async ({ commit }, query) => {
  const { data } = await InternalProjectService.paginatedSearch(query)
  commit('SET_LIST', data)

  return data
}

export const fetchNodes = async ({ commit }, query) => {
  const { data } = await InternalProjectService.nodes(query)
  commit('SET_LIST', data)

  return data
}

export const addItem = async ({ commit }, item) => {
  const { data } = await InternalProjectService.create(item)
  commit('ADD_TO_LIST', data)

  return data
}

export const getItemById = async (contect, id) => {
  const { data } = await InternalProjectService.getById(id)

  return data
}

export const updateItem = async ({ commit }, { _id, ...payload }) => {
  const { data } = await InternalProjectService.update(_id, payload)
  commit('UPDATE_LIST', data)

  return data
}

export const deleteItem = async ({ commit }, id) => {
  const { data } = await InternalProjectService.delete(id)
  commit('REMOVE_FROM_LIST', data._id)

  return data
}

export const duplicateItem = async ({ commit }, id) => {
  const { data } = await InternalProjectService.duplicate(id)
  commit('ADD_TO_LIST', data)

  return data
}

export const countPerState = async ({ commit }, payload) => {
  const { data } = await InternalProjectService.countPerState(payload)
  commit('DATA_COUNTED_PER_STATE', data)

  return data
}

export const exportCsv = async ({ commit, rootState }, { filters }) => {
  try {
    commit(mutationTypes.exportCsv.requesting)
    const res = await InternalProjectService.exportCsv(filters)
    commit(mutationTypes.exportCsv.success, { rootState })

    return res
  } catch (e) {
    commit(mutationTypes.exportCsv.failure, { payload: e, rootState })
  }
}
